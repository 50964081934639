import React, {Component} from 'react';
import Firebase from "../Firebase";
import ScoreBox from './ScoreBox';
import Event from './Event';
import '../css/index.css';
import bobRoss from '../pics/bobRoss.png';
import pollock from '../pics/pollock.png';
import uni from '../pics/uni.png';
import vanGogh from '../pics/vanGogh.png';

class Home extends Component {
    state = {
        loading: true,
        error: false,
        people: {},
        teams: {
            1: {
                name: '',
                people: {},
                logo: bobRoss
            },
            2: {
                name: '',
                people: {},
                logo: pollock
            },
            3: {
                name: '',
                people: {},
                logo: uni
            },
            4: {
                name: '',
                people: {},
                logo: vanGogh
            }
        },
        events: {},
        attendance: {},
        personRef: null,
        eventRef: null,
        attendanceRef: null,
        //headerHeight: '9em'
    };


    async componentDidMount() {
        const sheetID = '1-I-fErW1geVnbDTVqwDiaYHzyfTrWutk4HZjHyzYwmU';
        const personRef = Firebase.database().ref(sheetID + '/person');
        const teamRef = Firebase.database().ref(sheetID + '/team');
        const eventRef = Firebase.database().ref(sheetID + '/event');
        const attendanceRef = Firebase.database().ref(sheetID + '/attendance');
        const personPromise = new Promise((resolve, reject) => {
            personRef.on('value', (personSnapshot) => {
                const teams = this.state.teams;
                const people = this.state.people;
                if (!personSnapshot.val()) { //no employees, only happens on site setup
                    resolve();
                    return;
                }
                personSnapshot.val().forEach((person) => {
                    const statePerson = {...teams[person.team].people[person.id]};
                    if (!statePerson.score) {
                        statePerson.score = 0;
                    }
                    statePerson.name = person.name;
                    statePerson.team = person.team;
                    teams[person.team].people[person.id] = statePerson;
                    people[person.id] = {
                        team: person.team
                    }
                });
                this.setState({
                    teams
                });
                resolve();
            });
        });
        const eventPromise = new Promise((resolve, reject) => {
            eventRef.on('value', (eventSnapshot) => {
                const events = this.state.events;
                if (!eventSnapshot.val()) {
                    resolve();
                    return;
                }
                eventSnapshot.val().forEach((event) => {
                    events[event.id] = {
                        name: event.name,
                        team: event.team,
                        location: event.location,
                        description: event.description,
                        date: event.date.substr(0, 10),
                        time: event.time
                    };
                });
                this.setState({
                    events
                });
                resolve();
            });
        });
        const attendancePromise = new Promise((resolve, reject) => {
            attendanceRef.on('value', (attendanceSnapshot) => {
                const teams = this.state.teams;
                Object.keys(teams).forEach(teamID => {
                    const team = teams[teamID];
                    teams[teamID].total = 0;
                    Object.keys(team.people).forEach(personID => {
                        teams[teamID].people[personID].score = 0;
                    });
                });
                if (!attendanceSnapshot.val()) {
                    this.setState({
                        teams
                    });
                    resolve();
                    return;
                }
                attendanceSnapshot.val().forEach((record) => {
                    const personTeam = this.state.people[record.person].team;
                    const person = teams[personTeam].people[record.person];
                    const recordTeam = this.state.events[record.event].team;
                    person.score += 1;
                    teams[personTeam].total += 1;
                    if (recordTeam !== personTeam) {
                        teams[recordTeam].total += 1;
                    }
                    teams[personTeam].people[record.person] = person;
                });
                this.setState({
                    teams
                });
                resolve();
            });
        });
        const teamPromise = new Promise((resolve, reject) => {
            teamRef.once('value').then((teamSnapshot) => {
                const teams = this.state.teams;
                teamSnapshot.val().forEach((team) => {
                    teams[team.id].name = team.name;
                });
                this.setState({
                    teams
                });
                resolve();
            });
        });
        //people need to load before attendance and team
        const orderPromise = new Promise(async (resolve, reject) => {
            await personPromise;
            await Promise.all([attendancePromise, teamPromise]);
            resolve();
        });
        try {
            await Promise.all([orderPromise, eventPromise]); //event can load whenever
            this.setState({
                loading: false,
                //headerHeight: document.querySelector('#header').clientHeight + 30,
                personRef,
                eventRef,
                attendanceRef
            });
        } catch (exception) {
            console.log(exception);
            this.setState({
                loading: false,
                error: true,
                personRef,
                eventRef,
                attendanceRef
            });
        }
    }

    componentWillUnmount() {
        if (this.state.personRef) {
            this.state.personRef.off('value');
        }
        if (this.state.eventRef) {
            this.state.eventRef.off('value');
        }
        if (this.state.attendanceRef) {
            this.state.attendanceRef.off('value');
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <div id='header'>
                        <h1 style={{color: 'white'}}>Canvas Games</h1>
                    </div>
                    <h1>Canvas Games</h1>
                    <h2>Scores</h2>
                    <h3>Loading...</h3>
                </>
            );
        }
        if (this.state.error) {
            return (
                <>
                    <div id='header'>
                        <h1 style={{color: 'white'}}>Canvas Games</h1>
                    </div>
                    <div>Error. Reload page</div>
                </>
            );
        }
        const eventDates = {};
        const events = {};
        const eventKeys = Object.keys(this.state.events);
        if (eventKeys.length) {
            //date stuff
            const date = new Date();
            const year = date.getFullYear();
            let month = parseInt(date.getMonth()) + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let day = date.getDate();
            if (day < 10) {
                day = '0' + day;
            }
            const fullDate = year + '-' + month + '-' + day;

            //filter events
            eventKeys.forEach(key => {
                const event = this.state.events[key];
                if (event.date < fullDate) {
                    return;
                }
                const team = event.team;
                if (!eventDates[team]) {
                    events[team] = event;
                    eventDates[team] = event.date;
                    return;
                }
                if (event.date < eventDates[event.team]) {
                    events[team] = event;
                    eventDates[team] = event.date;
                }
            });
        }
        let sortedEvents;
        if (!Object.keys(events).length) {
            sortedEvents = [undefined];
        } else {
            sortedEvents = [];
            Object.keys(events).forEach(key => {
                const event = events[key];
                sortedEvents.push(event);
            });
            sortedEvents.sort(function (a, b) {
                if (a.date > b.date) {
                    return 1;
                }
                if (a.date < b.date) {
                    return -1;
                }
                return 0;
            });
        }

        return (
            <>
                <div id='header'>
                    <h1 style={{color: 'white'}}><strong>Canvas Games</strong></h1>
                </div>
                <div className='justifyCenter'>
                    <div id = 'pageContainer'>
                        <div style={{paddingBottom: '5em'}}>
                            <h2 style={{paddingBottom: '1em'}}>Scores</h2>
                            <div className="row">
                                {
                                    Object.keys(this.state.teams).map((teamID) => {
                                        return (
                                            <div key={teamID} className='col-12 col-lg-6 justifyCenter'
                                                 style={{paddingBottom: '2em'}}>
                                                <ScoreBox key={teamID} team={this.state.teams[teamID]}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <h2 style={{paddingBottom: '0.8em'}}>Upcoming Events</h2>
                        <div className='justifyCenter'>
                            <div className="row eventList">
                                <div className="col">
                                    {
                                        sortedEvents.map((event, index) => {
                                            let team;
                                            if (event !== undefined) {
                                                team = this.state.teams[event.team].name;
                                            }
                                            return (
                                                <Event key={index} event={event} team={team}/>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;