import React, {Component} from 'react';
import '../css/event.css';

class Event extends Component {
    render() {
        if (this.props.event === undefined) {
            return <h3>No upcoming events. Check back soon!</h3>
        }
        const dateArr = this.props.event.date.split('-');
        const date = dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
        const event = this.props.event;

        return (
            <>
                <div className='justifyCenter'>
                    <div>
                        <h4><strong>{event.name}</strong></h4>
                        <h5><strong>Team: {this.props.team}</strong></h5>
                        <h5><strong>Date: {date}</strong></h5>
                        <h5><strong>Time: {event.time}</strong></h5>
                        <h5><strong>Location: {event.location}</strong></h5>
                        <div>{event.description}</div>
                    </div>
                </div>
                <hr/>
            </>
        );
    }
}

export default Event;