import React, {Component} from 'react';
import '../css/scoreBox.css';

class ScoreBox extends Component {
    render() {
        const peopleObj = this.props.team.people;
        const people = Object.keys(peopleObj).map(personID => peopleObj[personID]);

        people.sort((a, b) => {
            if (a.score < b.score) {
                return 1;
            }
            if (a.score > b.score) {
                return -1;
            }
            const aNameArr = a.name.split(' ');
            const bNameArr = b.name.split(' ');
            if (aNameArr[1] > bNameArr[1]) {
                return 1;
            }
            if (aNameArr[1] < bNameArr[1]) {
                return -1;
            }
            if (aNameArr[0] > bNameArr[0]) {
                return 1;
            }
            if (aNameArr[0] < bNameArr[0]) {
                return -1;
            }
            return 0;
        });
        return (
            <div className='scoresContainer'>
                <div style={{paddingBottom: 5}}>
                    <div className="row">
                        <div className="col-auto" style={{paddingRight: 0}}>
                            <img src={this.props.team.logo} alt="logo"/>
                        </div>
                        <div className="col justifyCenter alignCenter" style={{paddingRight: '2em'}}>
                            <h3 style={{paddingRight: '1em'}}><strong>{this.props.team.name}</strong></h3>
                            <h3><strong>{this.props.team.total}</strong></h3>
                        </div>
                    </div>
                </div>
                <table style={{borderCollapse: 'collapse'}}>
                    <thead>
                    <tr>
                        <th style={{width: '20em', maxWidth: 500}}>
                            Person
                        </th>
                        <th>Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        people.map((person, index) => {
                            return (
                                <tr key={index}>
                                    <td key={'name' + index}>
                                        {person.name}
                                    </td>
                                    <td key={'score' + index} style={{textAlign: 'right'}}>
                                        {person.score}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ScoreBox;