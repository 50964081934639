import firebase from 'firebase';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAc3DybZYPCCY81JWWgn9IkTjYVAU7hrqQ",
    authDomain: "canvas-games-784fb.firebaseapp.com",
    databaseURL: "https://canvas-games-784fb.firebaseio.com",
    projectId: "canvas-games-784fb",
    storageBucket: "canvas-games-784fb.appspot.com",
    messagingSenderId: "82017615502",
    appId: "1:82017615502:web:f680cb88124fe7a0c253df"
};
firebase.initializeApp(firebaseConfig);

export default firebase;
